/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

const PostCard = (props) => {
  const post = props.post;
  const title = post.frontmatter.title || post.fields.slug;
  return (
    <Link
      sx={{
        color: "inherit",
        textDecoration: "none",
        m: [".5rem", ".5rem", "1rem"],
        textAlign: "left",
      }}
      to={post.slug.slice(5)}
    >
      <div
        sx={{
          backgroundColor: "secondary",
          borderRadius: "1rem",
          width: "100%",
          height: "100%",
          padding: ["1rem", "1rem", "2rem"],
          "&:hover": {
            boxShadow: (theme) => `0 0 1em ${theme.colors.highlighted}`,
          },
        }}
      >
        <p
          sx={{
            marginTop: "-.1em",
            fontWeight: "normal",
          }}
        >
          {post.frontmatter.date}
        </p>
        <h2
          sx={{
            marginTop: "-.5em",
          }}
        >
          {title}
        </h2>

        <p
          sx={{
            marginTop: "-1.5em",
            p: ".5em",
            fontWeight: "medium",
          }}
        >
          {post.frontmatter.description}
        </p>
      </div>
    </Link>
  );
};

export default PostCard;
