/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PostCard from "../components/PostCard.js";
//import SEO from "../components/seo";

const blog = ({ data }) => {
  const posts = data.allMdx.nodes;
  /* <SEO title={siteTitle} description={siteDescription} /> */
  return (
    <Layout styling={"layout.primary"} additionalStyles={{maxWidth: "90rem"}}>
      <div
        sx={{
          m: [".5rem", "1rem"],
        }}
      >
        <h2>Blog Posts</h2>
        <p>AWS, Serverless, React, and other things I find interesting</p>
      </div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["100%", "50% 50%"],
        }}
      >
        {posts.map((post) => {
          return <PostCard post={post} key={post.slug} />;
        })}
      </div>
    </Layout>
  );
};

export default blog;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "blog" } } }
    ) {
      nodes {
        slug
        frontmatter {
          date(formatString: "Do MMMM YYYY")
          description
          title
        }
      }
    }
  }
`;
